import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PortfolioMasonry from "../elements/portfolio/PortfolioMasonry";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import Brand from "../elements/Brand";
import Helmet from "../component/common/Helmet";
import Slider from "react-slick";
import PortfolioList from "../elements/portfolio/PortfolioList";
import { slickDot, portfolioSlick2 } from "../page-demo/script";
import { isMobile } from 'react-device-detect';

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Minimal',
        description: 'There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    }
]


function formatarOpacidade(numero) {
    var parteDecimal = (numero % 1) * 10
    var parteDecimalInteira = Math.floor(parteDecimal)
    console.log(parteDecimalInteira)
    return parteDecimalInteira
}

function adjustFontSize() {
    console.log('isMobile', isMobile)
    const containerWidth = document.querySelector('.inner').offsetWidth;
    const titleWidth = document.querySelector('.title').offsetWidth;
    const availableWidth = containerWidth - titleWidth; // Espaço disponível para o título

    const { minFontSize, maxFontSize } = this.state;

    if (titleWidth > containerWidth) {
        let newFontSize = Math.floor((availableWidth / titleWidth) * this.state.fontSize);
        newFontSize = Math.max(minFontSize, Math.min(newFontSize, maxFontSize));
        this.setState({ fontSize: newFontSize });
    } else {
        this.setState({ fontSize: 24 }); // Resetar para o tamanho inicial caso o texto caiba na div
    }
}
function formatarDinheiro(numero) {
    if (numero) {

        // Converte o número para string e substitui vírgulas por pontos, se houver
        var numeroFormatado = numero.toString().replace(',', '.');

        // Separa a parte inteira da parte decimal
        var partes = numeroFormatado.split('.');
        var parteInteira = partes[0];
        var parteDecimal = partes.length > 1 ? ',' + partes[1] : '';

        // Adiciona o separador de milhares na parte inteira
        parteInteira = parteInteira.replace(/\d(?=(\d{3})+(?!\d))/g, '$&.');

        // Adiciona o símbolo de moeda no início e concatena parte inteira e parte decimal
        numeroFormatado = 'R$ ' + parteInteira + parteDecimal;

        // Retorna o número formatado como string
        return numeroFormatado;
    } else {
        return ""
    }
}

class HomePortfolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titulo: '',
            subtitulo: '',
            backgroundUrl: '',
            descricaoprincipal: '',
            listaImoveis: [{}],
            opacidade: 0,
            fontSize: null, // Tamanho inicial da fonte

        };
    }
    componentDidMount() {
        // const hostname = 'window.location.hostnamename';
        // const hostname = window.location.hostname
        const hostname = window.location.hostname
        // Chamada para o webservice aqui
        // Exemplo de chamada para um webservice usando fetch

        window.addEventListener('resize', this.adjustFontSize);
        if (isMobile) {
            console.log('eeeeeeeeeeeeeeee')
            this.setState({
                fontSize: 24
            })
        } else {
        }
        fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-background/${hostname}`)
            .then(response => response.json())
            .then(data => {
                // Faça algo com os dados recebidos
                console.log('data', data)
                this.setState({
                    backgroundUrl: data.path_background_principal,
                    opacidade: data.path_background_principal_opacidade
                })
                console.log(data);
            })
            .catch(error => {
                console.error('Erro ao chamar o webservice:', error);
            });

        fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-titulo-subtitulo/${hostname}`)
            .then(response => response.json())
            .then(data => {
                // Faça algo com os dados recebidos
                console.log('data', data)
                this.setState({
                    titulo: data.titulo,
                    subtitulo: data.subtitulo,
                    descricaoprincipal: data.descricaoprincipal
                })
                console.log(data);
            })
            .catch(error => {
                console.error('Erro ao chamar o webservice:', error);
            });

        fetch(`${process.env.REACT_APP_API_BASE_URL}/consultar-ultimos-lancamentos/${hostname}`)
            .then(response => response.json())
            .then(data => {
                // Faça algo com os dados recebidos
                console.log('data', data)
                this.setState({
                    listaImoveis: data
                })
                console.log(data);
            })
            .catch(error => {
                console.error('Erro ao chamar o webservice:', error);
            });
    }
    render() {
        return (
            <Fragment>
                <Helmet pageTitle="Home Portfolio" />

                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div className="slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image" data-black-overlay={this.state.opacidade > 0 ? formatarOpacidade(this.state.opacidade) : null} style={{
                            backgroundImage:
                                `url('${this.state.backgroundUrl}')`
                        }} key={index} >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`} style={{ maxWidth: '100% !important' }}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {this.state.titulo ? <h2 className="title theme-gradient" style={this.state.fontSize !== null ? { fontSize: this.state.fontSize } : {}}>{this.state.titulo}</h2> : ''}
                                            {this.state.subtitulo ? <p className="description">{this.state.subtitulo}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`/imoveis`}>Buscar Imóveis</a></div> : ''}
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    ))}
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area   */}


                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2 className="title">Últimos Lançamentos</h2>
                                    <br></br>
                                    <p style={{ textAlign: 'justify' }}>{this.state.descricaoprincipal}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper portfolio-sacousel-inner mb--55">
                        <div className="portfolio-slick-activation mt--70 mt_sm--40">
                            <Slider {...this.state.listaImoveis}>
                                {this.state.listaImoveis.map((value, index) => (
                                    <a href={`/imovel-detalhe/${value.id}`}>
                                        <div className="portfolio" key={index} >
                                            <div className="thumbnail-inner">
                                                <div id={index} className={'thumbnail'} style={{ backgroundImage: `url('${value.primeira_foto}')` }}></div>
                                                <div id={index} className={'bg-blr-image'} style={{ backgroundImage: `url('${value.primeira_foto}')` }}></div>

                                            </div>
                                            <div className="content" >
                                                <div className="inner">
                                                    <p>{value.contrato}</p>
                                                    <h4><a href={`/imovel-detalhe/${value.id}`}>{value.titulo}</a></h4>
                                                    <p>{value.quantidade_quartos} quartos | {formatarDinheiro(value.preco)}</p>
                                                    <div className="portfolio-button">
                                                        <a className="rn-btn" href={`/imovel-detalhe/${value.id}`}>Conhecer</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}


                {/* Start Footer Area  */}
                <FooterTwo />
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment >
        )
    }
}
export default HomePortfolio;