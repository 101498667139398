import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import axios from 'axios'

const Result = () => {
    return (
        <p className="success-message">Sua mensagem foi enviada com sucesso! Entraremos em contato.</p>
    )
}

function ContactForm({ props }) {
    const [result, showresult] = useState(false);
    const [resulterror, showresulterror] = useState(false);
    const [status, setStatus] = useState('Enviar');

    const [email, setEmail] = useState('');
    const [assunto, setAssunto] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [celular, setCelular] = useState('');
    const [nome, setNome] = useState('');
    const [erro, setErro] = useState('');

    const sendEmail = (e) => {
        setStatus('Enviando')
        e.preventDefault();
        const hostname = window.location.hostname;
        // const hostname = window.location.hostname
        const requestOptions = {
            email, celular, assunto, mensagem, nome, dominio: hostname
        }

        axios
            .post(
                `${process.env.REACT_APP_API_BASE_URL}/enviar/email/cliente`,
                requestOptions,
            )
            .then(function (response) {
                showresult(true);
                setStatus('Enviado')

            })
            .catch(function (error) {
                showresulterror(true);
                console.error('Erro ao chamar o webservice:', error.response.data);
                setErro(error.response.data)
                setStatus('Enviar')


            })
    };

    setTimeout(() => {
        showresult(false);
    }, 50000);

    setTimeout(() => {
        showresulterror(false);
    }, 50000);

    return (
        <form action="" onSubmit={sendEmail}>
            <div className="rn-form-group">
                <input
                    type="text"
                    name="fullname"
                    placeholder="Seu Nome"
                    onChange={(e) => setNome(e.target.value)}
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}

                    placeholder="Seu Email"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="text"
                    name="phone"
                    onChange={(e) => setCelular(e.target.value)}

                    placeholder="Seu Celular"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="text"
                    onChange={(e) => setAssunto(e.target.value)}

                    name="subject"
                    placeholder="Subject"
                    required
                />
            </div>

            <div className="rn-form-group">
                <textarea
                    name="message"
                    onChange={(e) => setMensagem(e.target.value)}

                    placeholder="Sua Mensagem"
                    required
                >
                </textarea>
            </div>

            <div className="rn-form-group">
                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">{status}</button>
            </div>

            <div className="rn-form-group">
                {result ? <Result /> : null}
            </div>
            <div className="rn-form-group">
                {resulterror ? <p className="success-error">Sua mensagem não foi enviada! Erro:{erro}</p>
                    : null}
            </div>
        </form>
    )
}
export default ContactForm;
