import * as React from 'react';


export class Map extends React.Component {
  constructor(props) {
    super(props);

  }
  mapRef = React.createRef();
  state = {
    map: null
  };


  componentDidMount() {

    const coordenadas = this.props.id
    const coordenadaLng = this.props.lng
    const coordenadaLat = this.props.lat

    //aqui inicio

    const H = window.H;

    const platform = new H.service.Platform({
      apikey: "Zt2XDlWgrooQ9iZDT5bvfbnCmv1PCjVKj2PvnLAp2gw"
    });

    const defaultLayers = platform.createDefaultLayers();

    const map = new H.Map(
      this.mapRef.current,
      defaultLayers.vector.normal.map,
      {
        center: { lat: coordenadaLat, lng: coordenadaLng },
        zoom: 14,
        pixelRatio: window.devicePixelRatio || 3
      }
    );




    // MapEvents enables the event system
    // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
    // This variable is unused and is present for explanatory purposes
    // const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

    // Create the default UI components to allow the user to interact with them
    // This variable is unused
    // const ui = H.ui.UI.createDefault(map, defaultLayers);



    this.setState({ map }, () => {

      map.addObject(new H.map.Circle(
        // The central point of the circle
        { lat: coordenadaLat, lng: coordenadaLng },
        // The radius of the circle in meters
        600,
        {
          style: {
            strokeColor: 'rgba(96, 26, 249, 0.16)', // Color of the perimeter
            lineWidth: 2,
            fillColor: 'rgba(96, 26, 249, 0.16)'  // Color of the circle
          }
        }
      ));
      this.setState({ mostrarMapa: true })

    });

    this.setState({ mostrarMapa: false })



    //aqui fim


  }

  componentWillUnmount() {
    this.state.map.dispose();

  }

  addCircleToMap(map) {
    const H = window.H;



  }




  render() {
    return (
      // Set a height on the map so it will display
      <div ref={this.mapRef} style={{ height: "500px", borderRadius: '20px !important', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }} />
    );
  }
}

