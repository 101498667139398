import React, { useEffect, useState } from 'react';
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";

import logo from "../../assets/images/logo/logo.png";

// Função para editar o link de um item específico
const editarLink = (index, novoLink) => {
  const novaLista = [...socialShare]; // Criando uma cópia do estado atual

  // Se o novo link for nulo, remover a linha correspondente
  if (novoLink === null || novoLink === "") {
    novaLista.splice(index, 1);
  } else {
    // Caso contrário, atualizar o link do item específico
    novaLista[index].link = novoLink;
  }

  // Atualizando o estado com a nova lista
  setSocialShare(novaLista);
};

function formatarOpacidade(numero) {
  var parteDecimal = (numero % 1) * 10
  var parteDecimalInteira = Math.floor(parteDecimal)
  console.log(parteDecimalInteira)
  return parteDecimalInteira
}
const FooterTwo = () => {
  const [urlData, setUrlData] = useState('https://firebasestorage.googleapis.com/v0/b/imobil-415717.appspot.com/o/template%2Ffundo-branco.png?alt=media&token=fafb09ae-9fd4-46c5-9f3f-d0e10a63064b');
  const [footer, setFooter] = useState('');
  const [footerBackgroundOpacidade, setFooterBackgroundOpacidade] = useState(0)
  const [footerBackground, setFooterBackground] = useState('')
  const [socialShare, setSocialShare] = useState([
    { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
    { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
    { Social: <FaInstagram />, link: "https://www.instagram.com/" },
    { Social: <FaTwitter />, link: "https://twitter.com/" },
  ])
  const hostname = window.location.hostname;

  // const hostname = window.location.hostname
  useEffect(() => {
    // Código para executar o efeito colateral
    console.log('Componente montado ou atualizado');
    fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-logo/${hostname}`)
      .then(response => response.json())
      .then(data => {
        // Faça algo com os dados recebidos
        console.log('data', data)
        setUrlData(data.path_logo)
        console.log(data);
      })
      .catch(error => {
        console.error('Erro ao chamar o webservice:', error);
      });

    fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-background-footer/${hostname}`)
      .then(response => response.json())
      .then(data => {
        // Faça algo com os dados recebidos
        console.log('data', data)
        setFooterBackground(data.path_background_footer)
        setFooterBackgroundOpacidade(data.path_background_footer_opacidade)
        console.log(data);
      })
      .catch(error => {
        console.error('Erro ao chamar o webservice:', error);
      });

    fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-footer/${hostname}`)
      .then(response => response.json())
      .then(data => {
        // Faça algo com os dados recebidos
        console.log('data', data)
        setFooter(data.footer)
        console.log(data);
      })
      .catch(error => {
        console.error('Erro ao chamar o webservice:', error);
      });
    fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-redes-sociais/${hostname}`)
      .then(response => response.json())
      .then(data => {
        // Faça algo com os dados recebidos
        console.log('data', data)
        editarLink = (0, data.facebook)
        editarLink = (1, data.linkedin)
        editarLink = (2, data.instagram)
        editarLink = (3, data.twitter)

        console.log(data);
      })
      .catch(error => {
        console.error('Erro ao chamar o webservice:', error);
      });
    // Função de limpeza opcional
    return () => {
      // Código para limpar efeitos anteriores
      console.log('Componente desmontado');
    };
  }, [hostname]);
  return (
    <div
      className="footer-style-2 ptb--30 bg_image bg_image--1"
      data-black-overlay={footerBackgroundOpacidade > 0 ? formatarOpacidade(footerBackgroundOpacidade) : null} style={{
        backgroundImage:
          `url('${footerBackground}')`
      }}
    >
      <div className="wrapper plr--50 plr_sm--20">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner">
              <div className="logo text-center text-sm-left mb_sm--20">
                <a href="/home-one">
                  <img src={urlData} alt="IMOBIL Sites Imobiliários - Sites para Corretores Sites para Imobiliárias" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner text-center">
              <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                {socialShare.map((val, i) => (
                  <li key={i}>
                    <a href={`${val.link}`}>{val.Social}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
              <div className="text">
                <p>{footer}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FooterTwo;
