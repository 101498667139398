// Modal.js
import React, { useEffect, useState } from 'react';
import './Modal.css';
import axios from 'axios';
import {
  CSpinner,
} from '@coreui/react-pro'
import './Carregando.css';

const Carregando = () => {

  return (

    <div class="container-centralizado">
      <CSpinner color="secondary" variant="grow" />
    </div>
  );
};

export default Carregando;
