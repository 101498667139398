import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast, FiLayers, FiUsers, FiMonitor, FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
const ServiceList = []


function formatarOpacidade(numero) {
    var parteDecimal = (numero % 1) * 10
    var parteDecimalInteira = Math.floor(parteDecimal)
    console.log(parteDecimalInteira)
    return parteDecimalInteira
}


class Service extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titulo: '',
            opacidade: 0,
            subtitulo: '',
            backgroundUrl: '',
            valoresList: [
                {
                    icon: <FiCast />,
                    title: '',
                    description: ''
                },
                {
                    icon: <FiLayers />,
                    title: '',
                    description: ''
                },
                {
                    icon: <FiUsers />,
                    title: '',
                    description: ''
                },
            ]
        };
    }
    componentDidMount() {
        // const hostname = window.location.hostname;
        const hostname = window.location.hostname

        // const hostname = window.location.hostname
        // Chamada para o webservice aqui
        // Exemplo de chamada para um webservice usando fetch
        fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-background-sobre-nos/${hostname}`)
            .then(response => response.json())
            .then(data => {
                // Faça algo com os dados recebidos
                console.log('data', data)
                this.setState({
                    backgroundUrl: data.path_background_sobre_nos,
                    opacidade: data.path_background_sobre_nos_opacidade
                })
                console.log(data);
            })
            .catch(error => {
                console.error('Erro ao chamar o webservice:', error);
            });


        fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-descricao-sobre-nos/${hostname}`)
            .then(response => response.json())
            .then(data => {
                // Faça algo com os dados recebidos

                console.log('data', data)
                this.setState({
                    descricaosobrenos: data.descricaosobrenos,
                })
                const novoValoresList = [...this.state.valoresList]; // Faça uma cópia da lista existente
                novoValoresList[0].title = data.valores_1;
                novoValoresList[0].description = data.valores_conteudo_1;

                novoValoresList[1].title = data.valores_2;
                novoValoresList[1].description = data.valores_conteudo_2;

                novoValoresList[2].title = data.valores_3;
                novoValoresList[2].description = data.valores_conteudo_3;
                // Atualize o estado com os novos valores
                this.setState({ valoresList: novoValoresList });

                console.log(data);
            })
            .catch(error => {
                console.error('Erro ao chamar o webservice:', error);
            });
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Sobre Nòs' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                {/* <Breadcrumb title={'Sobre Nós'} style={{
                    backgroundImage:
                        `url('${this.state.backgroundUrl}') !important`
                }} /> */}
                <div className="breadcrumb-area rn-bg-color ptb--120 bg_image" style={{
                    backgroundImage:
                        `url('${this.state.backgroundUrl}')`
                }} data-black-overlay={this.state.opacidade > 0 ? formatarOpacidade(this.state.opacidade) : null}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner pt--100">
                                    {/* <h2 className="title">Sobre Nós</h2>
                                    <ul className="page-list">
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Sobre nós</h2>
                                    <br></br><br></br>
                                    <p>{this.state.descricaosobrenos}</p>
                                    <br></br>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {this.state.valoresList.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    {/* <a href=""> */}
                                    <div className="service service__style--2">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>
                                    {/* </a> */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;