import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import GoogleMapReact from 'google-map-react';
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

function formatarOpacidade(numero) {
    var parteDecimal = (numero % 1) * 10
    var parteDecimalInteira = Math.floor(parteDecimal)
    console.log(parteDecimalInteira)
    return parteDecimalInteira
}

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    constructor(props) {
        super(props);
        this.state = {
            celular: '',
            email: '',
            email_2: '',
            celular_2: '',
            endereco: '',
            opacidade: 0,
            backgroundUrl: ''

        };
    }

    componentDidMount() {
        // const hostname = window.location.hostname;
        // const hostname = window.location.hostname
        const hostname = window.location.hostname

        // Chamada para o webservice aqui
        // Exemplo de chamada para um webservice usando fetch

        fetch(`${process.env.REACT_APP_API_BASE_URL}/dados-contato/${hostname}`)
            .then(response => response.json())
            .then(data => {
                // Faça algo com os dados recebidos
                console.log('data', data)
                this.setState({
                    celular: data.celular,
                    celular_2: data.celular_2,
                    email_2: data.email_2,
                    email: data.email,
                    endereco: data.endereco
                })
                console.log(data);
            })
            .catch(error => {
                console.error('Erro ao chamar o webservice:', error);
            });
        fetch(`${process.env.REACT_APP_API_BASE_URL}/imagem-contato/${hostname}`)
            .then(response => response.json())
            .then(data => {
                // Faça algo com os dados recebidos
                console.log('data', data)
                this.setState({
                    backgroundUrl: data.path_background_contato,
                    opacidade: data.path_background_contato_opacidade

                })
                console.log(data);
            })
            .catch(error => {
                console.error('Erro ao chamar o webservice:', error);
            });




    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image" data-black-overlay={this.state.opacidade > 0 ? formatarOpacidade(this.state.opacidade) : null} style={{
                    maxHeight: '3px', backgroundImage:
                        `url('${this.state.backgroundUrl}')`
                }}>
                    {/* <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                 <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Entre em Contato</h2>
                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p>
                                </div> 
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* End Breadcrump Area */}
                {/* <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                              
                        </div>
        
                    </div>
                </div> */}

                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="section-title text-center mb--30">
                            <h2>Informações de Contato</h2>
                            <br></br>
                            <br></br>
                        </div>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <br></br>
                                        <h4 className="title">Contato por Telefone</h4>
                                        <p><a href={`tel:${this.state.celular}`}>{this.state.celular}</a></p>
                                        {this.state.celular_2 ?
                                            <p><a href={`tel:${this.state.celular_2}`}>{this.state.celular_2}</a></p>
                                            : false}

                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <br></br>
                                        <h4 className="title">Endereço de Email</h4>
                                        <p><a href={`mailto:${this.state.email}`}>{this.state.email}</a></p>
                                        <p><a href={`mailto:${this.state.email_2}`}>{this.state.email_2}</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <br></br>
                                        <h4 className="title">Endereço</h4>
                                        <p>{this.state.endereco}</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div>
                {/* End Contact Page Area  */}




            </React.Fragment>
        )
    }
}
export default Contact