import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";

import logoDefault from "../../assets/images/logo/logo.png";
import logoLight from "../../assets/images/logo/logo-light.png";
import logoDark from "../../assets/images/logo/logo-dark.png";
import logoSymbolDark from "../../assets/images/logo/logo-symbol-dark.png";
import logoSymbolLight from "../../assets/images/logo/logo-symbol-light.png";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoUrl: 'https://firebasestorage.googleapis.com/v0/b/imobil-415717.appspot.com/o/template%2Ffundo-branco.png?alt=media&token=fafb09ae-9fd4-46c5-9f3f-d0e10a63064b',
    };
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  componentDidMount() {
    const hostname = window.location.hostname;
    // const hostname = window.location.hostname
    // Chamada para o webservice aqui
    // Exemplo de chamada para um webservice usando fetch
    fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-titulo-subtitulo/${hostname}`)
      .then(response => response.json())
      .then(data => {
        // Faça algo com os dados recebidos
        console.log('data', data)
        this.setState({
          titulo: data.titulo,
          subtitulo: data.subtitulo
        })
        console.log(data);
      })
      .catch(error => {
        console.error('Erro ao chamar o webservice:', error);
      });

    fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-logo/${hostname}`)
      .then(response => response.json())
      .then(data => {
        // Faça algo com os dados recebidos
        console.log('data', data)
        this.setState({
          logoUrl: data.path_logo
        })
        console.log(data);
      })
      .catch(error => {
        console.error('Erro ao chamar o webservice:', error);
      });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  render() {
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { logo, color = "default-color" } = this.props;
    let logoUrl = <img src={this.state.logoUrl} alt="iMobil - Logo Cliente" />;

    // let logoUrl;
    // if (logo === "light") {
    // } else if (logo === "dark") {
    //   logoUrl = <img src={logoDark} alt="Digital Agency" />;
    // } else if (logo === "symbol-dark") {
    //   logoUrl = <img src={logoSymbolDark} alt="Digital Agency" />;
    // } else if (logo === "symbol-light") {
    //   logoUrl = <img src={logoSymbolLight} alt="Digital Agency" />;
    // } else {
    //   logoUrl = <img src={logoDefault} alt="Digital Agency" />;
    // }

    return (
      <header
        className={`header-area formobile-menu header--transparent ${color}`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">{logoUrl}</a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <Link to="/">Início</Link>
                </li>
                <li>
                  <Link to="/sobre-nos">Sobre Nós</Link>
                </li>
                <li>
                  <Link to="/imoveis">Imóveis</Link>
                </li>
                {/* <li>
                  <Link to="/google-map">Explorar por Mapa</Link>
                </li> */}
                <li>
                  <Link to="/contact">Contato</Link>
                </li>
              </ul>
            </nav>
            <div className="header-btn">
              <a
                className="rn-btn"
                href="/imoveis"
              >
                <span>Ver imóveis</span>
              </a>
            </div>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
