import React, { Component } from "react";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";



class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footer: '',
      celular: '',
      email: '',
      socialShare: [
        { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
        { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
        { Social: <FaInstagram />, link: "https://www.instagram.com/" },
        { Social: <FaTwitter />, link: "https://twitter.com/" },
      ]

    };
  }
  editarLink = (index, novoLink) => {
    const novaLista = [...this.state.socialShare]; // Criando uma cópia do estado atual

    // Se o novo link for nulo ou vazio, remover a linha correspondente
    if (novoLink === null || novoLink === "") {
      novaLista.splice(index, 1);
    } else {
      // Caso contrário, atualizar o link do item específico
      novaLista[index].link = novoLink;
    }

    // Atualizando o estado com a nova lista
    this.setState({ socialShare: novaLista });
  };

  componentDidMount() {
    const hostname = window.location.hostname;
    // const hostname = window.location.hostname
    // Chamada para o webservice aqui
    // Exemplo de chamada para um webservice usando fetch

    fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-footer/${hostname}`)
      .then(response => response.json())
      .then(data => {
        // Faça algo com os dados recebidos
        console.log('data', data)
        this.setState({
          footer: data.footer
        })
        console.log(data);
      })
      .catch(error => {
        console.error('Erro ao chamar o webservice:', error);
      });

    fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-redes-sociais/${hostname}`)
      .then(response => response.json())
      .then(data => {
        // Faça algo com os dados recebidos
        console.log('data', data)
        editarLink = (0, data.facebook)
        editarLink = (1, data.linkedin)
        editarLink = (2, data.instagram)
        editarLink = (3, data.twitter)

        console.log(data);
      })
      .catch(error => {
        console.error('Erro ao chamar o webservice:', error);
      });
    fetch(`${process.env.REACT_APP_API_BASE_URL}/dados-contato/${hostname}`)
      .then(response => response.json())
      .then(data => {
        // Faça algo com os dados recebidos
        console.log('data', data)
        this.setState({
          celular: data.celular,
          email: data.email
        })

        console.log(data);
      })
      .catch(error => {
        console.error('Erro ao chamar o webservice:', error);
      });
  }
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>Entre em contato</span>
                    <h2>
                      Anuncie <br /> Conosco
                    </h2>
                    <a className="rn-button-style--2" href="/contact">
                      <span>Entre em Contato</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="6">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4>Dados de Contato</h4>
                        <ul className="ft-link">
                          <li>
                            <a href={`mailto:${this.state.email}`}>
                              {this.state.email}
                            </a>
                          </li>
                          <li>
                            <a>{this.state.celular}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link">
                        <h4>Redes Sociais</h4>


                        <div className="social-share-inner">
                          <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                            {this.state.socialShare.map((val, i) => (
                              <li key={i}>
                                <a href={`${val.link}`}>{val.Social}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Widget  */}

                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>
                          {this.state.footer}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
