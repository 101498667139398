// Modal.js
import React, { useEffect, useState } from 'react';
import './Modal.css';
import axios from 'axios';
import {
  CFormLabel,
  CMultiSelect,
  CFormRange,
  CCol,
  CSpinner,
  CRow,
  CFormInput,
  CFormSelect,
} from '@coreui/react-pro'
import { InputGroup } from 'react-bootstrap';


const Modal = ({ isOpen, onClose, children, onSubmit, dadosInput, onOpenModal }) => {
  const hostname = window.location.hostname;
  // const hostname = 'luis.sistemasimobiliarios.com.br'
  const [listaEstados, setListaEstados] = useState([])
  const [estadoSelected, setEstadoSelected] = useState([])
  const [cidadesSelected, setCidadesSelected] = useState([])
  const [listaDeCidades, setListaDeCidades] = useState([])
  const [listaDeBairros, setListaDeBairros] = useState([])
  const [bairrosSelected, setBairrosSelected] = useState([])
  const [max, setMax] = useState(5000000000)
  const [precoMaximo, setPrecoMaximo] = useState(5000000000)
  const [precoMinimo, setPrecoMinimo] = useState(0)
  const [listaTiposDeImovel, setListaTiposDeImovel] = useState([])
  const [listaTiposContrato, setListaTiposContrato] = useState([])
  const [tipoDeContratoSelected, setTipoDeContratoSelected] = useState([])
  const [tipoDeImovelSelected, setTipoDeImovelSelected] = useState([])
  const [listaDeProximidades, setListaDeProximidades] = useState([])
  const [listaDeProximidadesSelected, setListaDeProximidadesSelected] = useState([])
  const handleClose = () => {
    onClose();

  };

  const handleSubmit = () => {
    const dados = {
      estado: estadoSelected.length > 0 ? estadoSelected : "",
      cidade: cidadesSelected.length > 0 ? cidadesSelected : "",
      bairros: bairrosSelected,
      tipo: tipoDeImovelSelected,
      contrato: tipoDeContratoSelected.length > 0 ? tipoDeContratoSelected[0].text : "",
      precoMinimo: precoMinimo == 0 ? '' : removerFormatacaoMoeda(precoMinimo),
      precoMaximo: precoMaximo == 5000000000 ? '' : removerFormatacaoMoeda(precoMaximo),

    }
    onSubmit(dados);
    onClose();

  };

  useEffect(() => {
    console.log('useEffect Acionado', dadosInput)

    // const fetchCaracteristicasImoveis = () => {
    //   axios
    //     .get(`${process.env.REACT_APP_API_BASE_URL}/lista/caracteristicas-imovel`)
    //     .then(function (response) {
    //       setListaDeCaracteristicasImoveis(transformarArray(response.data))
    //     })
    //     .catch(function (error) {
    //       console.log('error', error)
    //     })
    // }
    // const fetchCaracteristicasCondominio = () => {
    //   axios
    //     .get(`${process.env.REACT_APP_API_BASE_URL}/lista/caracteristicas-condominio`)
    //     .then(function (response) {
    //       setListaDeCaracteristicasCondominio(transformarArray(response.data))
    //     })
    //     .catch(function (error) {
    //       console.log('error', error)

    //     })
    // }
    const fetchEstados = () => {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/lista/estados/dominio`, {
          dominio: hostname
        })
        .then(function (response) {
          setListaEstados(transformarArrayEstado(response.data))
        })
        .catch(function (error) {
          console.log('error', error)

        })
    }
    // const fetchProximidades = () => {
    //   axios
    //     .get(`${process.env.REACT_APP_API_BASE_URL}/lista/proximidades`)
    //     .then(function (response) {
    //       setListaDeProximidades(transformarArrayProximidades(response.data))
    //     })
    //     .catch(function (error) {
    //       console.log('error', error)
    //     })
    // }
    const fetchListaTipoImovel = () => {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/lista/tipo-imovel`)
        .then(function (response) {
          setListaTiposDeImovel(transformarArrayTipo(response.data))
        })
        .catch(function (error) {
          console.log('error', error)
        })
    }
    const fetchListaTipoContrato = () => {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/lista/tipo-contrato`)
        .then(function (response) {
          setListaTiposContrato(transformarArrayTipo(response.data))
        })
        .catch(function (error) {
          console.log('error', error)
        })
    }
    // fetchCaracteristicasImoveis()
    fetchEstados()
    // fetchCaracteristicasCondominio()
    // fetchProximidades()
    fetchListaTipoImovel()
    fetchListaTipoContrato()
  }, [])
  function transformarArray(caracteristicas) {
    return caracteristicas.map((item) => ({
      value: item.caracteristica,
      text: item.caracteristica,
    }))
  }
  function transformarArrayEstado(estados) {
    return estados.map((item) => ({
      value: item.estado,
      text: item.estado,
    }))
  }
  function transformarArrayCidade(estados) {
    return estados.map((item) => ({
      value: item.cidade,
      text: item.cidade,
    }))
  }
  function transformarArrayBairro(bairros) {
    return bairros.map((item) => ({
      value: item.bairro,
      text: item.bairro,
    }))
  }
  function transformarArrayProximidades(proximidades) {
    return proximidades.map((item) => ({
      value: item.proximidade,
      text: item.proximidade,
    }))
  }
  function handleBairroSelected(e) {
    if (e.length == 0) {
      setListaDeBairros(marcarSelecionados(listaDeBairros, []))
    } else {
      setListaDeBairros(marcarSelecionados(listaDeBairros, e))

      var bairros = []
      for (let i = 0; i < e.length; i++) {
        bairros.push(e[i].text)
      }
      setBairrosSelected(bairros)
    }

  }
  function handleTipoDeCidades(e) {
    if (e.length == 0) {
      setListaDeCidades(marcarSelecionado(listaDeCidades, []))

    } else {
      setListaDeCidades(marcarSelecionado(listaDeCidades, e[0].text))
    }
    setBairrosSelected([])
    setListaDeBairros([])
    if (e.length > 0) {
      fetchBairros(e[0].text)
      setCidadesSelected(e[0].text)
    }
  }
  function marcarSelecionado(array, valor) {
    return array.map(item => {
      if (item.text === valor) {
        return { ...item, selected: true };
      }
      return item;
    });
  }
  function marcarSelecionados(array, valores) {
    return array.map(item => {
      if (valores.some(val => val.text === item.text)) {
        return { ...item, selected: true };
      }
      return item;
    });
  }
  function handleTipoDeEstado(e) {
    if (e.length == 0) {
      setListaEstados(marcarSelecionado(listaEstados, []))

    } else {
      setListaEstados(marcarSelecionado(listaEstados, e[0].text))
    }
    setCidadesSelected([])
    setListaDeCidades([])
    setListaDeBairros([])
    setBairrosSelected([])
    if (e.length > 0) {
      fetchCidades(e[0].text)
      setEstadoSelected(e[0].text)
    }

  }
  function fetchBairros(e) {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/lista/bairros/dominio`, {
        dominio: hostname,
        cidade: e,
        estado: estadoSelected
      })
      .then(function (response) {
        setListaDeBairros(transformarArrayBairro(response.data))
      })
      .catch(function (error) {
        console.log('error', error)

      })
  }
  function fetchCidades(e) {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/lista/cidades/dominio`, {
        dominio: hostname,
        estado: e
      })
      .then(function (response) {
        setListaDeCidades(transformarArrayCidade(response.data))
      })
      .catch(function (error) {
        console.log('error', error)

      })


  }
  function transformarArrayTipo(tipos) {
    return tipos.map((item) => ({
      value: item.tipo,
      text: item.tipo,
    }))
  }
  function removerFormatacaoMoeda(valor) {
    if (valor == 0.00) {
      return 5000000;
    }
    // Remove o símbolo "R$"
    // const valorSemReal = valor.replace('R$', '');
    // console.log('valor', valor)
    // const valorSemPonto = valor.replace(',', '.')
    // const valorComPonto = valorSemPonto.replace(',', '.')
    // console.log('valorcomponto,', valorComPonto)
    // // Retorna a string sem formatação
    // console.log('valor', valorComPonto)
    return valor;
  }
  const handleTipoDeContrato = async (e) => {
    if (e.length == 0) {
      setListaTiposContrato(marcarSelecionado(listaTiposContrato, 'nulllll'))

      setTipoDeContratoSelected([])
    } else {
      setListaTiposContrato(marcarSelecionado(listaTiposContrato, e[0].text))

      setTipoDeContratoSelected(e)
    }

  }
  const handleTipoImovel = async (e) => {
    console.log('eeee', e)
    if (e.length == 0) {
      setListaTiposDeImovel(marcarSelecionados(listaTiposDeImovel, []))
      setTipoDeImovelSelected([])
    } else {
      setListaTiposDeImovel(marcarSelecionados(listaTiposDeImovel, e))

      var tipo = []
      for (let i = 0; i < e.length; i++) {
        tipo.push(e[i].text)
      }

      setTipoDeImovelSelected(tipo)
    }
  }
  const handlePrecoChange = (e, tipo) => {
    console.log('e')
    let value = e.target.value
    console.log('value', value)
    if (value == '0,0') {
      console.log('zerouuuu')
      if (tipo == 1) {
        console.log('precomaximo zerou')

        setPrecoMaximo(5000000000)
      } else if (tipo == 2) {
        setPrecoMinimo(0)
      }
    } else {
      value = value.replace(/\D/g, '') // Remove todos os caracteres que não são dígitos
      value = (value / 100).toFixed(2) // Divide por 100 e formata como valor monetário
      if (tipo == 1) {
        setPrecoMaximo(value)
      } else if (tipo == 2) {
        setPrecoMinimo(value)
      }
    }

  }
  // const handlePrecoChange = (e, tipo) => {
  //   console.log('')
  //   console.log('e.target.value', e.target.value)
  //   let valorFormatado = ""

  //   let valor = e.target.value

  //   if (valor === '') {
  //     valorFormatado = ""
  //     console.log('iffff')
  //   } else {
  //     console.log('elsee')

  //     // Remove tudo exceto dígitos
  //     const valorLimpo = valor.replace(/[^\d]/g, '');
  //     valorFormatado = valorLimpo
  //     // Formata o valor para a moeda brasileira
  //     let valorF = Number(valorLimpo / 100).toFixed(2)
  //     valorFormatado = valorF.toLocaleString('pt-BR')

  //     console.log('valor formatadooo', valorFormatado)
  //     console.log(valorFormatado)
  //   }
  //   if (tipo == 1) {
  //     setPrecoMinimo(valorFormatado)
  //   } else if (tipo == 2) {
  //     setPrecoMaximo(valorFormatado)
  //   }
  // }
  function formatarNumeroVisivel(numero) {
    // Converte o número para uma string e substitui pontos por vírgulas
    let numeroFormatado = numero.toString().replace('.', ',')

    // Verifica se há casas decimais
    if (numeroFormatado.indexOf(',') === -1) {
      // Se não houver casas decimais, adiciona ",00" no final
      numeroFormatado += ',00'
    } else {
      // Se houver casas decimais, verifica se há menos de dois dígitos depois da vírgula
      let partes = numeroFormatado.split(',')
      if (partes[1].length < 2) {
        // Se houver menos de dois dígitos, adiciona um zero
        numeroFormatado += '0'
      }
    }

    // Adiciona pontos como separadores de milhares
    numeroFormatado = numeroFormatado.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

    return numeroFormatado
  }
  function transformarArrayTipo(tipos) {
    return tipos.map((item) => ({
      value: item.tipo,
      text: item.tipo,
    }))
  }
  const handleModalClick = (e) => {
    e.stopPropagation();
  };
  // if (!isOpen) return null;

  return (
    <div className="overlay" onClick={handleClose}>
      <div className="content-modal" onClick={handleModalClick}  >
        <div className="content-modal-center" >

          <h4 style={{ margin: '0 auto' }}>Filtros</h4>
        </div>
        <br></br>
        <CRow>

          <CCol sm={12}>


            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormcnae">Tipo de Imóvel:</CFormLabel>
              <CMultiSelect
                multiple={true}
                size="lg"
                options={listaTiposDeImovel}
                placeholder="Tipo de Imóvel"
                onChange={(e) => handleTipoImovel(e)}
              />
            </div>
          </CCol>
          <CCol sm={12}>

            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormcnae">Aluguel ou Compra:</CFormLabel>
              <CMultiSelect
                multiple={false}
                size="lg"
                options={listaTiposContrato}
                placeholder="Tipo de Contrato"
                onChange={(e) => handleTipoDeContrato(e)}
              />
            </div>
          </CCol>
          <CCol sm={12}>

            <div className="mb-3">

              <CFormLabel htmlFor="estadoselect">Estado:</CFormLabel>
              <CMultiSelect
                multiple={false}
                size="lg"
                loading={listaEstados.length == 0}
                options={listaEstados}
                placeholder="Tipo de Estados"
                onChange={(e) => handleTipoDeEstado(e)}
              />
            </div>
          </CCol>


          <CCol sm={12}>

            <div className="mb-3">
              <CFormLabel htmlFor="cidadesselect">Cidades:</CFormLabel>
              <CMultiSelect
                multiple={false}
                options={listaDeCidades}
                size="lg"
                loading={listaDeCidades.length == 0}
                placeholder="Tipo de Cidades"
                onChange={(e) => handleTipoDeCidades(e)}
              />
            </div>
          </CCol>
          <CCol sm={12}>
            <div className="mb-3">
              <CFormLabel htmlFor="bairrosselect">Bairros:</CFormLabel>
              <CMultiSelect
                multiple={true}
                size="lg"
                loading={listaDeBairros.length == 0}
                options={listaDeBairros}
                placeholder="Tipo de Bairros"
                onChange={(e) => handleBairroSelected(e)}
              />
            </div>
          </CCol>

        </CRow>
        <CRow>
          <CCol sm={6}>
            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput2">Preço Mínimo:</CFormLabel>
              <CFormInput
                type="text"
                id="preco"
                size="lg"
                className="form-control"
                value={precoMinimo === 0 ? '' : formatarNumeroVisivel(precoMinimo)}
                onChange={(e) => handlePrecoChange(e, 2)}
              />
            </div>
          </CCol>
          <CCol sm={6}>
            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput2">Preço Máximo:</CFormLabel>
              <CFormInput
                type="text"
                id="preco"
                size="lg"
                className="form-control"
                value={precoMaximo == 5000000000 ? '' : formatarNumeroVisivel(precoMaximo)}
                onChange={(e) => handlePrecoChange(e, 1)}
              />
            </div>
          </CCol>
          <div className="row">
            <div className="col-lg-12">
              <div className="view-more-btn mt--60 text-center">
                <a onClick={() => handleSubmit()} className="rn-button-style--2 btn-solid"><span>Buscar</span></a>
              </div>
            </div>
          </div>
        </CRow>
      </div>
    </div >
  );
};

export default Modal;
