import React, { Component } from "react";
import ContactForm from "./ContactForm";

import about from "../../assets/images/about/about-6.jpg";

class ContactTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ''

    };
  }
  componentDidMount() {
    const hostname = window.location.hostname;
    // const hostname = 'estrelasimoveis.sistemasimobiliarios.com.br'
    // Chamada para o webservice aqui
    // Exemplo de chamada para um webservice usando fetch

    fetch(`${process.env.REACT_APP_API_BASE_URL}/imagem-contato/${hostname}`)
      .then(response => response.json())
      .then(data => {
        // Faça algo com os dados recebidos
        console.log('data', data)
        this.setState({
          url: data.path_imagem_contato
        })
        console.log(data);
      })
      .catch(error => {
        console.error('Erro ao chamar o webservice:', error);
      });




  }
  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-center">
            <div className="col-lg-6 order-2 order-lg-1 d-flex align-items-center">
              <div className="section-title text-left mb--50">
                <h2 className="title">Entre em Contato</h2>
                <br></br>
                <div className="form-wrapper">
                  <ContactForm />
                </div>
              </div>

            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img src={this.state.url} alt="iMobil - Contato" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactTwo;
