// React Required
import React, { Component } from "react";
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";

// Create Import File
import './scss/style.scss'

import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
import Demo from "./page-demo/Demo";
import MainDemo from "./home/MainDemo";
import Startup from "./home/Startup";
import Paralax from "./home/Paralax";
import HomePortfolio from "./home/HomePortfolio";
import DigitalAgency from "./home/DigitalAgency";
import CreativeAgency from "./home/CreativeAgency";
import PersonalPortfolio from "./home/PersonalPortfolio";
import Business from "./home/Business";
import StudioAgency from "./home/StudioAgency";
import PortfolioLanding from "./home/PortfolioLanding";
import CreativeLanding from "./home/CreativeLanding";
import HomeParticles from "./home/HomeParticles";
import CreativePortfolio from "./home/CreativePortfolio";
import DesignerPortfolio from "./home/DesignerPortfolio";
import InteriorLanding from "./home/Interior";
import CorporateBusiness from "./home/CorporateBusiness";
import InteractiveAgency from "./home/InteractiveAgency";

// Dark Home Layout
import DarkMainDemo from "./dark/MainDemo";
import DarkPortfolioLanding from "./dark/PortfolioLanding";

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";

// Blocks Layout

import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import './_variable_colors.scss'; // Importe seu arquivo SCSS

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titulo: 'Teste',
      subtitulo: 'Teste'
    };
  }
  componentDidMount() {

    const hostname = window.location.hostname;
    // const hostname = window.location.hostname

    // Chamada para o webservice aqui
    // Exemplo de chamada para um webservice usando fetch

    fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-cores/${hostname}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.primary_color) {
          console.log('data.primary_color', data.primary_color)
          document.documentElement.style.setProperty('--primary-color-test', data.primary_color); // Altera o valor de --primary-color-test para vermelho
          document.documentElement.style.setProperty('--secundary-color-test', data.secundary_color); // Altera o valor de --secondary-color-test para verde
          document.documentElement.style.setProperty('--primary-color-titulo-test', data.primary_color_back); // Altera o valor de --secondary-color-test para verde
          document.documentElement.style.setProperty('--primary-color-titulo-test', data.primary_color_titulo); // Altera o valor de --secondary-color-test para verde
          document.documentElement.style.setProperty('--secundary-color-titulo-test', data.secundary_color_titulo); // Altera o valor de --secondary-color-test para verde


        }
      })
      .catch(error => console.error('Ocorreu um erro ao obter o valor da cor:', error));
    fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-titulo-subtitulo/${hostname}`)
      .then(response => response.json())
      .then(data => {
        document.title = data.titulo;
      })
      .catch(error => {
        console.error('Erro ao chamar o webservice:', error);
      });

    fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-logo-aba/${hostname}`)
      .then(response => response.json())
      .then(data => {
        const logoUrl = data.path_logo_aba;
        // Define o URL da imagem como o ícone no cabeçalho do documento
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = logoUrl;
        document.getElementsByTagName('head')[0].appendChild(link);
      })
      .catch(error => {
        console.error('Erro ao chamar o webservice:', error);
      });
  }

  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Demo} /> */}
            <Route
              exact
              path={`/contact`}
              component={Contact}
            />
            <Route
              exact
              path={`/`}
              component={HomePortfolio}
            />
            <Route
              exact
              path={`/sobre-nos`}
              component={Service}
            />
            <Route
              exact
              path={`/imoveis`}
              component={Portfolio}
            />

            {/* <Route
              exact
              path={`/google-map`}
              component={GoogleMap}
            /> */}



            <Route
              exact
              path={`/imovel-detalhe/:id`}
              component={PortfolioDetails}
            />
            <Route component={error404} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
serviceWorker.register();
