import React, { useEffect, useState, useRef } from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Modal from "../elements/common/Modal"
import Bolinha from "../elements/common/Bolinha"
import Carregando from "../elements/common/Carregando"
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Slider from "react-slick";
import PortfolioList from "../elements/portfolio/PortfolioList";
import { slickDot, portfolioSlick2 } from "../page-demo/script";
import './Portfolio.css'
import axios from 'axios'
const Portfolio = () => {
    const [imoveisList, setImoveisList] = useState([])
    const [imoveisListShow, setImoveisListShow] = useState([])
    const [showButton, setShowButton] = useState(true)
    const [dadosInput, setDadosInput] = useState([])
    const [isScrolled, setIsScrolled] = useState(false)
    const [opacidade, setOpacidade] = useState(0)
    const [scrollLongEnough, setScrollLongEnough] = useState(false);

    const [backgroundUrl, setBackgroundUrl] = useState('')
    const [loading, setLoading] = useState(true)
    const [tipoDeImovelSelected, setTipoDeImovelSelected] = useState([])
    const [listaDeCaracteristicasImoveis, setListaDeCaracteristicasImoveis] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const prevScrollY = useRef(0);

    const openModal = () => {
        setIsModalOpen(true);
    };
    const verMais = () => {
        const updatedArray = imoveisList.slice(0, imoveisListShow.length + 18);
        setImoveisListShow(updatedArray);
        console.log('imoveisList.data.length', imoveisList.length)
        console.log('updatedArray.length', updatedArray.length)
        if (imoveisList.length > updatedArray.length) {
            setShowButton(true)
        } else {
            setShowButton(false)
        }

    }

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        const scrollDifference = prevScrollY.current - currentScrollY;
        console.log('currentScrollY', currentScrollY)
        console.log('scrollDifference', scrollDifference)
        const marginError = 420; // Defina uma margem de erro adequada para o início da página

        if (currentScrollY < marginError) {
            console.log('Está no início da página!');
            setIsScrolled(false);

            // Coloque aqui o código que deseja executar quando o usuário estiver no início da página
        } else {
            if (scrollDifference > 10) {
                setIsScrolled(true);
                console.log('Rolou para cima!');
                prevScrollY.current = currentScrollY;

                currentScrollY
                // Coloque aqui o código que deseja executar quando o usuário rolar para cima
            } else if (scrollDifference < -10) {
                setIsScrolled(false);
                console.log('Rolou para baixo!');
                prevScrollY.current = currentScrollY;

                // Coloque aqui o código que deseja executar quando o usuário rolar para baixo
            }
        }


    };


    function formatarOpacidade(numero) {
        var parteDecimal = (numero % 1) * 10
        var parteDecimalInteira = Math.floor(parteDecimal)
        return parteDecimalInteira
    }
    const submitFilter = (dados) => {
        setDadosInput(dados)
        setLoading(true)
        setImoveisListShow([])
        setImoveisList([])
        const response = axios
            .post(
                `${process.env.REACT_APP_API_BASE_URL}/consulta-imoveis`,
                {
                    dominio: hostname,
                    dados: dados
                }
            )
            .then(function (response) {
                setImoveisList(response.data)
                console.log('quantidade de imoveis', response.data.length)
                const updatedArray = response.data.slice(0, 9);
                setImoveisListShow(updatedArray);
                setLoading(false)

                if (response.data.length > updatedArray.length) {
                    setShowButton(true)
                } else {
                    setShowButton(false)
                }
            })
            .catch((error) => {
                setLoading(false)

            })

    }
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const hostname = window.location.hostname;

    // const hostname = 'mansaodobosque.imobilsitesimobiliarios.com.br'
    // const hostname = window.location.hostname
    // Chamada para o webservice aqui
    // Exemplo de chamada para um webservice usando fetch
    useEffect(() => {
        setLoading(true)


        fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-background-imoveis/${hostname}`)
            .then(response => response.json())
            .then(data => {
                // Faça algo com os dados recebidos
                setBackgroundUrl(data.path_background_imoveis)
                setOpacidade(data.path_background_imoveis_opacidade)
            })
            .catch(error => {
                console.error('Erro ao chamar o webservice:', error);
            });

        const response = axios
            .post(
                `${process.env.REACT_APP_API_BASE_URL}/consulta-imoveis`,
                {
                    dominio: hostname,
                }
            )
            .then(function (response) {
                setImoveisList(response.data)
                const updatedArray = response.data.slice(0, 9);
                setImoveisListShow(updatedArray);
                setLoading(false)

                if (response.data.length > updatedArray.length) {
                    setShowButton(true)
                } else {
                    setShowButton(false)
                }
            })
            .catch((error) => {
                setLoading(false)

            })
        const fetchCaracteristicasImoveis = () => {
            axios
                .get(`${process.env.REACT_APP_API_BASE_URL}/lista/caracteristicas-imovel`)
                .then(function (response) {
                    setListaDeCaracteristicasImoveis(transformarArray(response.data))
                })
                .catch(function (error) {
                    // Swal.fire({
                    //     title: 'Erros ao carregar seus data!',
                    //     text: 'Atualize a página e tente novamente',
                    //     icon: 'error',
                    //     confirmButtonText: 'OK',
                    // })
                })
        }
        fetchCaracteristicasImoveis()

        window.addEventListener('scroll', handleScroll);

        // Remove o event listener quando o componente é desmontado
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Passando um array vazio para garantir que useEffect seja chamado apenas uma vez
    function formatarDinheiro(numero) {
        if (numero) {

            // Converte o número para string e substitui vírgulas por pontos, se houver
            var numeroFormatado = numero.toString().replace(',', '.');

            // Separa a parte inteira da parte decimal
            var partes = numeroFormatado.split('.');
            var parteInteira = partes[0];
            var parteDecimal = partes.length > 1 ? ',' + partes[1] : '';

            // Adiciona o separador de milhares na parte inteira
            parteInteira = parteInteira.replace(/\d(?=(\d{3})+(?!\d))/g, '$&.');

            // Adiciona o símbolo de moeda no início e concatena parte inteira e parte decimal
            numeroFormatado = 'R$ ' + parteInteira + parteDecimal;

            // Retorna o número formatado como string
            return numeroFormatado;
        } else {
            return ""
        }
    }
    const handleCaracteristicasImovel = async (e) => {
        setListaDeCaracteristicasImovelSelected(e)
    }
    function transformarArray(caracteristicas) {
        return caracteristicas.map((item) => ({
            value: item.id,
            text: item.caracteristica,
        }))
    }
    function extrairParteInteira(numeroString) {
        // Divide a string pelo ponto decimal
        const partes = numeroString.split('.');

        // Retorna a parte inteira (primeiro elemento após a divisão)
        return partes[0];
    }
    return (
        <>
            <PageHelmet pageTitle='Counters' />
            <div style={{ display: !isModalOpen ? 'none' : 'block' }}>
                <Modal onClose={closeModal} onSubmit={submitFilter} dadosInput={dadosInput} >
                </Modal>
            </div>
            <div>


                <div className={isScrolled ? "fixed-div-view-demo" : "fixed-div-view-demo-invisivel"} style={{
                    opacity: isScrolled ? '1' : '0'
                }}>
                    <div className="container view-more-btn text-center" style={{ paddingTop: '0vh', width: '1000px !important' }}>
                        <a className="rn-button-style--2 btn-solid" onClick={openModal}><span>Filtrar Imóveis</span></a>

                    </div>
                </div>
            </div>
            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            <div className="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1"
                data-black-overlay={opacidade > 0 ? formatarOpacidade(opacidade) : null}
                style={{
                    backgroundImage:
                        `url('${backgroundUrl}')`
                }}
            >

            </div>

            < main >

                <div className="portfolio-area bg_color--5" style={{ minHeight: '100vh' }}>
                    <div className="portfolio-sacousel-inner">
                        <div className="container">

                            <div className="view-more-btn text-center" style={{ paddingTop: '6vh', width: '1000px !important' }}>
                                <a className="rn-button-style--2 btn-solid" onClick={openModal}><span>Filtrar Imóveis</span></a>

                            </div>

                            {loading ?
                                <Carregando></Carregando> :


                                <div className="row property-list" style={{
                                    zIndex: '10'
                                }}>
                                    {imoveisListShow.map((value, index) => (
                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                                            <a href={`/imovel-detalhe/${value.id}`}>
                                                <div className={`portfolio text-left mt--40`}>
                                                    <div className="thumbnail-inner">
                                                        <div className={`thumbnail`} style={{ backgroundImage: `url('${value.fotos ? value.fotos[0].url : ''}')` }}></div>
                                                        <div className={`bg-blr-image`} style={{ backgroundImage: `url('${value.fotos ? value.fotos[0].url : ''}')` }}></div>
                                                    </div>
                                                    <div className="content">
                                                        <div className="inner">
                                                            <p>{value.contrato} | {value.quantidade_quartos} {value.quantidade_quartos > 1 ? "quartos" : "quarto"} | {extrairParteInteira(value.area_total)} m³</p>
                                                            <h6><a href={`/imovel-detalhe/${value.id}`}>{value.titulo}</a></h6>
                                                            <h4><a href={`/imovel-detalhe/${value.id}`}>{formatarDinheiro(value.preco)}</a></h4>
                                                            {/* <div className="portfolio-button">
                                                        <a className="rn-btn" href="/portfolio-details">Ver Mais</a>
                                                    </div> */}
                                                        </div>
                                                    </div>
                                                    {/* <Link className="link-overlay" to="/portfolio-details"></Link> */}
                                                </div>
                                            </a>
                                        </div>

                                    ))}
                                </div>
                            }

                            {showButton ?
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="view-more-btn mt--60 text-center">
                                            <a className="rn-button-style--2 btn-solid" onClick={verMais}><span>Ver mais imóveis</span></a>
                                        </div>
                                    </div>
                                </div>

                                : false}
                        </div>
                    </div>
                </div>
            </main >

            <br></br>


        </>

    )
}

export default Portfolio;