import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'; // Importante para adicionar os estilos CSS
import './PortifolioDetails.css'
import { Map } from './Map'
import { isMobile } from 'react-device-detect'; // Importe a função isMobile
import Swal from 'sweetalert2'
import copy from 'copy-to-clipboard';

import whatslogo from './whats.gif'
class PortfolioDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      showImage: false,
      imovel: {},
      localizacao: {},
      existeLocalizacao: false,
      principal: '',
      imagens: [],
      caracteristicasImovel: [],
      caracteristicasCondominio: [],
      proximidades: []
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  formatarDinheiro(numero) {
    if (numero) {

      // Converte o número para string e substitui vírgulas por pontos, se houver
      var numeroFormatado = numero.toString().replace(',', '.');

      // Separa a parte inteira da parte decimal
      var partes = numeroFormatado.split('.');
      var parteInteira = partes[0];
      var parteDecimal = partes.length > 1 ? ',' + partes[1] : '';

      // Adiciona o separador de milhares na parte inteira
      parteInteira = parteInteira.replace(/\d(?=(\d{3})+(?!\d))/g, '$&.');

      // Adiciona o símbolo de moeda no início e concatena parte inteira e parte decimal
      numeroFormatado = 'R$ ' + parteInteira + parteDecimal;

      // Retorna o número formatado como string
      return numeroFormatado;
    } else {
      return ""
    }
  }
  verTelefone(texto) {

    console.log('ver telefone')
    Swal.fire({
      title: 'Entre em contato!',
      text: texto,
      icon: 'success',
      confirmButtonText: "Copiar"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        copy(texto);
        Swal.fire({
          title: 'Copiado!',
          icon: 'success',
        })
      }
    });
  }


  componentDidMount() {
    const { match } = this.props;
    const id = match.params.id;

    console.log('id', id);

    Promise.all([
      fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-imovel/${id}`).then(response => response.json()),
      fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-caracteristicas-imovel/${id}`).then(response => response.json()),
      fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-caracteristicas-condominio/${id}`).then(response => response.json()),
      fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-proximidades/${id}`).then(response => response.json()),
      fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-fotos/${id}`).then(response => response.json()),
      fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-localizacao-aproximada/${id}`).then(response => response.json())

    ]).then(([imovelData, caracteristicasImovelData, caracteristicasCondominioData, proximidadesData, fotosData, localizacao]) => {
      console.log('imovelData', imovelData);
      console.log('caracteristicasImovelData', caracteristicasImovelData);
      console.log('caracteristicasCondominioData', caracteristicasCondominioData);
      console.log('proximidadesData', proximidadesData);
      console.log('fotosData', fotosData);

      const imagensAtualizadas = fotosData.map(novaImagem => ({
        original: novaImagem.url,
        thumbnail: novaImagem.url,
      }));
      console.log('localizacao length', localizacao.length)
      console.log('localizacao', localizacao)

      this.setState({
        imovel: imovelData,
        caracteristicasImovel: caracteristicasImovelData,
        caracteristicasCondominio: caracteristicasCondominioData,
        proximidades: proximidadesData,
        imagens: imagensAtualizadas,
        localizacao: localizacao,
        principal: fotosData.length > 0 ? fotosData[0].url : null,
      }, () => {
        this.setState({
          existeLocalizacao: this.state.localizacao.coordenada_latitude.length > 0,
          showImage: true

        })
      });
    }).catch(error => {
      console.error('Erro ao chamar o webservice:', error);
    });
  }

  // componentDidMount() {
  //   const { match } = this.props;
  //   const id = match.params.id;

  //   console.log('id', id)

  //   fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-imovel/${id}`)
  //     .then(response => response.json())
  //     .then(data => {
  //       this.setState({
  //         imovel: data
  //       })
  //       console.log('data', data)
  //     })
  //     .catch(error => console.error('Ocorreu um erro ao obter o valor da cor:', error));

  //   fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-caracteristicas-imovel/${id}`)
  //     .then(response => response.json())
  //     .then(data => {
  //       this.setState({
  //         caracteristicasImovel: data
  //       }, () => {
  //         console.log('hista satte cara', this.state.caracteristicasImovel);
  //       });
  //     })
  //     .catch(error => console.error('Ocorreu um erro ao obter o valor da cor:', error));

  //   fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-caracteristicas-condominio/${id}`)
  //     .then(response => response.json())
  //     .then(data => {
  //       console.log('data', data)
  //       this.setState({
  //         caracteristicasCondominio: data
  //       })
  //     })
  //     .catch(error => {
  //       console.error('Erro ao chamar o webservice:', error);
  //     });
  //   fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-proximidades/${id}`)
  //     .then(response => response.json())
  //     .then(data => {
  //       console.log('data', data)
  //       this.setState({
  //         proximidades: data
  //       })
  //     })
  //     .catch(error => {
  //       console.error('Erro ao chamar o webservice:', error);
  //     });
  //   fetch(`${process.env.REACT_APP_API_BASE_URL}/consulta-fotos/${id}`)
  //     .then(response => response.json())
  //     .then(data => {
  //       console.log('data', data)
  //       const imagensAtualizadas = [...this.state.imagens];
  //       this.setState({
  //         principal: data[0].url
  //       })
  //       for (const novaImagem of data) {
  //         imagensAtualizadas.push({
  //           original: novaImagem.url,
  //           thumbnail: novaImagem.url,
  //         });
  //       }
  //       this.setState({
  //         imagens: imagensAtualizadas
  //       }, () => {
  //         this.setState({
  //           showImage: true
  //         })
  //       });
  //     })
  //     .catch(error => {
  //       console.error('Erro ao chamar o webservice:', error);
  //     });
  // }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Portfolio Details" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"
          data-black-overlay="7"
          style={{
            backgroundImage:
              `url('${this.state.principal}')`
          }}
        >

        </div>

        <div className="bg_color--1" style={{ backgroundColor: '#f8f8ff' }}>
          <div className="container container-demo" style={{ backgroundColor: 'white' }}>
            <div className="row">
              <div className="col-lg-12">
                <div className="row">

                  <div className="col-lg-7">


                    <h2 style={isMobile ? { fontSize: 30, textAlign: 'justify' } : {}}>{this.state.imovel.tipo} para {this.state.imovel.contrato == "Compra e Venda" ? "Venda" : "Aluguel"} em {this.state.imovel.cidade}</h2>
                  </div>
                  <div className="col-lg-5">
                    {isMobile ?
                      <h3 style={{ textAlign: 'right' }}>{this.formatarDinheiro(this.state.imovel.preco)}</h3>
                      :

                      <h2 style={{ textAlign: 'right' }}>{this.formatarDinheiro(this.state.imovel.preco)}</h2>
                    }
                    <h4 style={{ textAlign: 'right' }}>Condomínio {this.formatarDinheiro(this.state.imovel.condominio)}</h4>
                    <h4 style={{ textAlign: 'right' }}>IPTU {this.formatarDinheiro(this.state.imovel.iptu)}</h4>
                  </div>
                </div>

                <p className="subtitle" style={{ marginTop: '5vh', textAlign: 'justify' }}>
                  <h3 style={isMobile ? { fontSize: 20 } : {}}>{this.state.imovel.titulo}</h3>

                  {this.state.imovel.descricao}{" "}
                </p>
                <br></br>
                <div className="row">



                  <div className="col-lg-10">
                    {this.state.existeLocalizacao ? <div className="container">
                      <p>Localizado em <strong>{this.state.imovel.estado}</strong> / <strong>{this.state.imovel.cidade}</strong> / <strong>{this.state.imovel.bairro}</strong></p>

                    </div> : false}
                    {this.state.showImage ?
                      <div className="">
                        {/* Start Single Portfolio */}
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="related-work text-center mt--30">
                            <div className="thumb">
                              {this.state.imagens.length > 0 ?
                                <ImageGallery items={this.state.imagens} />
                                : <p style={{textAlign: 'center'}}>As imagens desse imóvel ainda não foram cadastradas.</p>
                              }
                            </div>
                            {/* <div className="inner">
                    <h4>
                      <a href="/portfolio-details">Digital Analysis</a>
                    </h4>
                    <span className="category">Technique</span>
                  </div> */}
                          </div>
                        </div>

                      </div>
                      : <></>}
                  </div>
                  <div className="col-lg-2">
                    <div className="">
                      <div className="inner">

                        <div className="portfolio-view-list flex-wrap">

                          <div className="port-view" style={{ marginTop: '20px' }}>
                            <h4 style={{ textAlign: 'center' }}>Quartos</h4>

                            <p style={{ textAlign: 'center' }}>{this.state.imovel.quantidade_quartos}</p>
                          </div>

                          <div className="port-view port-view-demo">
                            <h4 style={{ textAlign: 'center' }}>Banheiros</h4>

                            <p style={{ textAlign: 'center' }}>{this.state.imovel.quantidade_banheiros}</p>
                          </div>

                          <div className="port-view port-view-demo">
                            <h4 style={{ textAlign: 'center' }}>Vagas</h4>
                            <p style={{ textAlign: 'center' }}>{this.state.imovel.quantidade_garagem}</p>
                          </div>
                          <div className="port-view port-view-demo">
                            <h4 style={{ textAlign: 'center' }}>Área Total</h4>
                            <p style={{ textAlign: 'center' }}>{this.state.imovel.area_total} m³</p>
                          </div>
                          <div className="port-view port-view-demo">
                            <h4 style={{ textAlign: 'center' }}>Área Construída</h4>
                            <p style={{ textAlign: 'center' }}>{this.state.imovel.area_construida} m³</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
                <div className="row">


                  <div className="col-lg-12">
                    {this.state.caracteristicasImovel.length > 0 ?
                      <div className="col-lg-12 margin-top-custom">
                        <div className="row">
                          <h3 style={{ textAlign: 'left', marginBottom: '6vh' }}>Características do Imóvel</h3>
                          {this.state.caracteristicasImovel.map((caracteristica) => (
                            <div className="col-lg-3">
                              <li style={{ fontSize: '19px' }}>
                                {caracteristica.caracteristica}{" "}
                              </li>

                            </div>
                          ))}
                          <br></br>
                        </div>
                      </div>
                      : false}



                    {this.state.caracteristicasCondominio.length > 0 ?
                      <div className="col-lg-12 margin-top-custom">
                        <div className="row">
                          <h3 style={{ textAlign: 'left', marginBottom: '6vh' }}>Características do Condomínio</h3>
                          {this.state.caracteristicasCondominio.map((caracteristica) => (
                            <div className="col-lg-3">
                              <li style={{ fontSize: '19px' }}>
                                {caracteristica.caracteristica}{" "}
                              </li>

                            </div>
                          ))}
                        </div>
                      </div>
                      : false}



                    {this.state.proximidades.length > 0 ?
                      <div className="col-lg-12 margin-top-custom">
                        <div className="row">
                          <h3 style={{ textAlign: 'left', marginBottom: '6vh' }}>Proximidades</h3>
                          {this.state.proximidades.map((caracteristica) => (
                            <div className="col-lg-3">
                              <li style={{ fontSize: '19px' }}>
                                {caracteristica.proximidade}{" "}
                              </li>

                            </div>
                          ))}
                        </div>
                      </div>
                      : false}
                  </div>


                </div>


                <div className="col-lg-12">



                  <div className="col-lg-12 margin-top-custom">
                    <h3 style={{ textAlign: 'left', marginBottom: '6vh' }}>Localização</h3>

                  </div>


                </div>
              </div>
            </div>

            {this.state.existeLocalizacao ?
              <div className="">
                <Map lat={this.state.localizacao.coordenada_latitude} lng={this.state.localizacao.coordenada_longitude} />
                <br></br> </div>
              :
              <div className="container">

                <p>A visualização do mapa dessa localização ainda esta indisponível.</p>
              </div>}
            <div className="col-lg-12" style={{ paddingTop: '50px' }}>
              <div className="section-title text-center" >
                <h4>Gostou deste Imóvel?</h4>
                <br></br>
                <div className="row">

                  <div className="col-lg-4" >
                    <div className=" view-more-btn text-center" style={{ paddingTop: '0vh', width: '1000px !important' }}>
                      <a className="rn-button-style--2 btn-solid" onClick={() => this.verTelefone(this.state.imovel.celular)}><span>Telefone</span></a>

                    </div>
                    <br></br>
                  </div>
                  <div className="col-lg-4" >
                    <div className=" view-more-btn text-center" style={{ paddingTop: '0vh', width: '1000px !important' }}>
                      <a className="rn-button-style--2 btn-solid" onClick={() => window.open(`http://api.whatsapp.com/send?phone=%+55${this.state.imovel.celular}%&text=Olá! Tenho interesse no imóvel ${window.location.href}.  `)}><span>WhatsApp</span></a>

                    </div>
                    <br></br>

                  </div>
                  <div className="col-lg-4" >
                    <div className=" view-more-btn text-center" style={{ paddingTop: '0vh', width: '1000px !important' }}>
                      <a className="rn-button-style--2 btn-solid" onClick={() => this.verTelefone(this.state.imovel.email)}><span>Email</span></a>

                    </div>
                    <br></br>

                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
        {/* End Portfolio Details */}

        {/* Start Related Work */}
        <div className="">
          <div className="" >
            <div className="">

            </div>

          </div>
        </div>
        {/* End Related Work */}
        {isMobile &&
          <div className="whatsapp-floating-button">
            <a href={`http://api.whatsapp.com/send?phone=%+55${this.state.imovel.celular}%&text=Olá! Tenho interesse no imóvel ${window.location.href}.  `} target="_blank" rel="noopener noreferrer">
              <img src={whatslogo} alt="WhatsApp" />
            </a>
          </div>
        }

        {/* End Back To Top */}
      </React.Fragment>
    );
  }
}
export default PortfolioDetails;
